















































































































import Vue from 'vue'
import LongPress from 'vue-directive-long-press'
import {
  PropValidator,
} from 'vue/types/options'
import {
  Id,
} from '@/types/base'
import {
  Place,
} from '@/types/place'
import {
  chapterStore,
} from '@/store'
import {
  Chapter,
} from '@/types/chapter'
import {
  newChapter,
} from '@/util/chapter'
import {
  newPlace,
} from '@/util/place'
import cloneDeep from 'clone-deep'
import {
  Icon,
} from '@/types'

export default Vue.extend({
  directives: {
    'long-press': LongPress,
  },
  props: {
    place: {
      default: () => ({
        id: ``,
        x: 0,
        y: 0,
        name: ``,
        translation: ``,
        color: `red`,
        icon: ``,
        chapterIds: [
        ],
      }),
      type: Object,
    } as PropValidator<(Place & Id)>,
  },
  data: () => ({
    isShowMenu: false,
    isMoveButton: false,
    newX: 0,
    newY: 0,
    place_: newPlace(),
  }),
  computed: {
    popupWidth: (): number => 320,
    placeChapters (): (Chapter & Id)[] {
      return this.place_.chapterIds?.map((id) => chapterStore.dataById(id) || {
        id,
        ...newChapter(),
      }) ?? [
      ]
    },
    icons (): Record<string, Icon> {
      return this.$global.icons
    },
  },
  watch: {
    place: {
      immediate: true,
      handler (place: Place) {
        this.place_ = cloneDeep(place)
      },
    },
    isShowMenu (status: boolean) {
      if (!status) return
      chapterStore.loadList(this.place_.chapterIds ?? [
      ])
    },
  },
  methods: {
    showTooltip (status: boolean) {
      this.isMoveButton = status
      this.isShowMenu = false
    },
    updateTooltip ({
      x, y,
    }: MouseEvent) {
      if (!this.isMoveButton) return
      const newX = Math.round(x / 4.8)
      const newY = Math.round((y - 64 - 72) / 4.8)
      this.place_.x = this.newX = newX
      this.place_.y = this.newY = newY
    },
  },
})
